import { render, staticRenderFns } from "./accountSettings.vue?vue&type=template&id=0c3453ca&scoped=true&"
import script from "./accountSettings.vue?vue&type=script&setup=true&lang=js&"
export * from "./accountSettings.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./accountSettings.vue?vue&type=style&index=0&id=0c3453ca&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c3453ca",
  null
  
)

export default component.exports